<template>
  <div
    v-if="disciplineData"
    class="training-box bordered-lg"
    :style="{
      backgroundImage:
        !disciplineComingSoon &&
        `url(${pathImages}disciplines/${disciplineData.id}-training-practice${disciplineData.id === 2 ? '-ibu' : ''}.avif?v1)`,
    }"
  >
    <div class="training-box-header">
      <div class="training-box-header-logo">
        <app-discipline-icon :discipline-id="disciplineData.id" :size="70" />
      </div>
      <section class="w-5/6 h-full flex flex-col justify-center">
        <div class="training-box-header-title">
          <short-text
            class="font-bold uppercase text-texts-standard-additional text-40"
            :text="$translateDiscipline(disciplineData.id)"
            :first-cut="22"
            :second-cut="30"
          />
        </div>
        <div class="yellow-strip training-box-header-strip" />
      </section>
    </div>
    <section v-if="!disciplineData.locked">
      <div class="training-box-middle">
        <section v-if="disciplineData.state === DISCIPLINE_TRAINED" class="w-full h-full">
          <div class="training-box-middle-purchased" />
          <div class="training-box-middle-text">
            <p class="text-texts-standard-upgrade text-40 font-bold uppercase">
              {{ textTrainCompleted }}
            </p>
          </div>
        </section>
      </div>
      <footer class="absolute bottom-0 left-0 w-full">
        <div v-if="trainer" class="training-box-count flexing">
          <div class="training-box-count-content">
            <p class="text-34 text-texts-standard-default not-italic">
              {{ disciplineData.state == 'trained' ? 1 : 0 }}/1
            </p>
          </div>
        </div>
        <div class="badge-level training-box-badge">
          {{ disciplineData.mechanicStats?.disciplineLevel ?? 0 }}
        </div>

        <div class="training-box-bottom">
          <div class="training-box-bottom-text">
            <p class="text-texts-standard-default text-30 font-bold">
              {{ textAttrValue }}
            </p>
          </div>
          <div class="training-box-bottom-value">
            <p class="text-texts-standard-important text-50 font-bold attributeText">
              {{ $filters.$formatNumber(disciplineData.userAttribute ?? 0) }}
            </p>
            <div
              class="attributeIcon"
              :class="'discipline icon-discipline-' + disciplineData.id + '-light-48'"
            />
          </div>
          <app-progress-bar
            class="training-box-bottom-progress"
            :bar-width="28.188"
            :bar-height="1.313"
            :actual="disciplineData.userStats?.trainingPoints"
            :goal="disciplineData.trainingPointsTarget"
          />
        </div>
      </footer>
    </section>

    <!-- LOCKED BOX -->
    <section v-else class="h-full w-full">
      <div class="training-box-locked">
        <div class="training-box-middle-purchased">
          <div
            class="training-box-locked-content relative"
            :class="{ 'coming-soon': disciplineComingSoon }"
          >
            <app-icon
              :icon-name="lockedIcon"
              class="training-box-locked-icon"
              :class="{ 'coming-soon': disciplineComingSoon }"
            />
            <div class="training-box-locked-text">
              <p class="text-texts-standard-additional text-36 font-bold uppercase">
                {{ disciplineComingSoon ? 'Coming soon' : textLocked }}
              </p>
            </div>
            <div
              v-if="!disciplineComingSoon"
              class="training-box-locked-level text-texts-standard-default"
            >
              <!-- eslint-disable -->
              <p class="text-texts-standard-default text-30" v-html="requiredText" />
              <!-- eslint-enable -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ShortText from '@/components/ShortText.vue'
import { DISCIPLINE_TRAINED, pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type Discipline from '@/interfaces/global/Discipline'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  DISCIPLINE_TRAINED: typeof DISCIPLINE_TRAINED
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'TrainingBox',
  components: {
    ShortText,
  },
  props: {
    trainer: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    textLocked: {
      type: String,
      default: '',
    },
    textLevelRequired: {
      type: String,
      default: '',
    },
    textAttrValue: {
      type: String,
      default: '',
    },
    textTrainCompleted: {
      type: String,
      default: '',
    },
    disciplineData: {
      type: Object as PropType<Nullable<Discipline>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      DISCIPLINE_TRAINED,
      pathImages,
    }
  },
  computed: {
    disciplineComingSoon(): boolean {
      return (
        this.disciplineData.levelToUnlock === undefined || this.disciplineData.levelToUnlock >= 99
      )
    },
    requiredText(): string {
      if (this.disciplineComingSoon) {
        return 'Coming soon'
      }
      return this.$replacePlaceholder(
        this.textLevelRequired,
        '%s',
        '<span class="text-texts-standard-important">' +
          this.disciplineData.levelToUnlock +
          '</span>',
      )
    },
    lockedIcon(): string {
      return this.disciplineComingSoon ? 'time' : 'lock-lg'
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/training/componentTrainingBox.scss';
@import '@/assets/styles/components/icons/discipline-icons.scss';

.attributeIcon {
  width: 3rem;
  height: 3rem;
}

.attributeText {
  margin-right: 0.75rem;
}
</style>
