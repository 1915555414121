<template>
  <div class="unlock-discipline h-full w-full" :class="$isMobile() && 'unlock-discipline-mobile'">
    <level-up-title :texts="texts" />
    <main class="unlock-discipline-content flexing">
      <div class="unlock-discipline-left">
        <div ref="animation1" class="unlock-discipline-left-title animated-element--faded">
          <app-discipline-icon :discipline-id="Number(disciplineId)" :size="120" />
          <p
            class="text-50 uppercase font-bold"
            :class="{
              'text-texts-standard-default': $isSsm,
            }"
          >
            {{ $translateDiscipline(disciplineId) }}
          </p>
        </div>
        <div ref="animation2" class="unlock-discipline-left-text animated-element--faded">
          <p class="text-texts-standard-default text-32">
            {{ $t('discipline.disciplineDescription_' + disciplineId) }}
          </p>
        </div>
      </div>
      <div class="unlock-discipline-right">
        <div
          ref="animation3"
          class="unlock-discipline-right-image"
          :style="{
            backgroundImage:
              'url(' +
              pathImages +
              'disciplines/' +
              disciplineId +
              `-unlocked-discipline${disciplineId === '2' ? '-ibu' : ''}.avif?v1)`,
          }"
        />
      </div>
    </main>
    <section class="unlock-discipline-footer__wrapper w-full h-full safe-area">
      <footer class="unlock-discipline-footer">
        <app-button
          btn-size="md"
          btn-type="primary"
          :btn-text="$t('button.back')"
          add-class="unlock-discipline-btn-back absolute"
          @click="$emit('back')"
        />
        <app-button
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          add-class="unlock-discipline-btn-continue absolute"
          @click="$emit('close')"
        />
      </footer>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { pathImages } from '@/globalVariables'
import { animateElement, playSound, skipAnimationOnClick } from '@/helpers'
import LevelUpTitle from '@/components/LevelUp/LevelUpTitle.vue'

interface ComponentData {
  pathImages: typeof pathImages
  texts: {
    first: string
    second: string
  }
}

export default defineComponent({
  name: 'LevelUpUnlockDiscipline',
  components: {
    LevelUpTitle,
  },
  props: {
    disciplineId: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'back'],
  data(): ComponentData {
    return {
      pathImages,
      texts: {
        first: 'common.congratulations',
        second: 'common.newDisciplineUnlocked',
      },
    }
  },
  mounted(): void {
    this.animateScene()
    playSound('discipline_unlock')
    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  methods: {
    animateScene(): void {
      animateElement(
        this.$refs.animation1,
        'fade-in, slide-in-to-right',
        '1s',
        200,
        'cubic-bezier(.77,.01,.51,1)',
      )
      animateElement(
        this.$refs.animation2,
        'fade-in, slide-in-to-right',
        '1s',
        300,
        'cubic-bezier(.77,.01,.51,1)',
      )
      animateElement(
        this.$refs.animation3,
        'zoom-in-out-low, flash-blur',
        '1s',
        900,
        'cubic-bezier(.77,.01,.51,1)',
      )
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      if ((e.target as HTMLElement).id === 'level-up-btn') return

      // it is necessary to use querySelector,
      // ref is allocated only for animated component objects
      const el = this.$el.querySelector('.unlock-discipline-content')
      skipAnimationOnClick(e, el, this.$refs, !!this.disciplineId, null)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/level-up/unlock-discipline.scss';
</style>
