<template>
  <div
    class="progress-bar-main bg-black flex items-center relative"
    :style="{
      width: barWidth + 'rem',
      height: barHeight + 'rem',
      background: barBackground,
      // 1.75rem for letters size, 1.5rem for combined left and right padding
      '--progress-bar-left-side-count-width': `${`${progressBarLeftCount}`.length * 1.75 + 1.5}rem`,
      '--progress-bar-right-side-count-width': `${`${progressBarRightCount}`.length * 1.75 + 1.5}rem`,
    }"
  >
    <div
      class="progress-bar-main-line h-full"
      :class="{ 'width-animation': isAnimated }"
      :style="{ width: lineWidth + '%' }"
    />
    <div class="progress-bar-main-text absolute left-1/2 transform -translate-x-1/2" />
    <section
      v-if="counts && !showCustomContent"
      class="progress-bar-counts h-full flexing"
      :style="{ fontSize: textSize + 'rem' }"
    >
      <div v-if="showSideCounts" class="progress-bar-left-count absolute">
        <p class="text-texts-standard-default font-36 font-bold">
          {{ progressBarLeftCount }}
        </p>
      </div>
      <div
        v-if="showSideCounts"
        class="progress-bar-right-count absolute text-texts-standard-important"
        :class="{ 'opacity-50': lockedIcon }"
      >
        <p class="text-texts-standard-important text-40 font-bold">
          {{ progressBarRightCount }}
        </p>
      </div>
      <div v-if="showBottomStatus" class="progress-status-bottom absolute flexing">
        <p class="text-texts-standard-default text-28">
          <span class="progress-status-bottom--actual">{{ $filters.$formatNumber(actual) }}</span> /
          <span class="progress-status-bottom--goal">{{ $filters.$formatNumber(goal) }}</span>
        </p>

        <app-main-icon
          v-if="statusIcon !== ''"
          class="progress-status-bottom-icon"
          :icon-size="32"
          :icon-name="statusIcon"
        />
      </div>
    </section>
    <section
      v-if="showCustomContent"
      class="progress-bar-counts h-full flexing"
      :style="{ fontSize: textSize + 'rem' }"
    >
      <div
        class="progress-status-bottom absolute flexing text-texts-standard-default text-28 uppercase font-bold"
      >
        <slot name="customContent" />
      </div>
    </section>
    <app-icon v-if="lockedIcon" icon-name="lock-sm" class="mx-auto pointer-events-none" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  lineWidth: number
  previousActual: Nullable<number>
  isAnimated: boolean
}

export default defineComponent({
  name: 'AppProgressBar',
  props: {
    actual: {
      type: Number,
      default: 0,
    },
    goal: {
      type: Number,
      default: 0,
    },
    experience: {
      type: Number,
      default: null,
    },
    countsActual: {
      type: Number,
      default: null,
    },
    barHeight: {
      type: Number,
      default: 1.875,
    },
    barWidth: {
      type: Number,
      default: 21.875,
    },
    barBackground: {
      type: String,
      default: 'black',
    },
    counts: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: Number,
      default: 1.75,
    },
    statusIcon: {
      type: String,
      default: '',
    },
    showBottomStatus: {
      type: Boolean,
      default: true,
    },
    showSideCounts: {
      type: Boolean,
      default: true,
    },
    disciplineChanged: {
      type: Boolean,
      default: false,
    },
    showCustomContent: {
      type: Boolean,
      default: false,
    },
    lockedIcon: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      lineWidth: 0,
      previousActual: null,
      isAnimated: true,
    }
  },
  computed: {
    progressBarLeftCount(): number {
      return this.countsActual ? this.countsActual : this.actual
    },
    progressBarRightCount(): number {
      return this.countsActual ? this.countsActual + 1 : this.goal
    },
  },
  watch: {
    actual(currentValue: number, previousValue: number): void {
      if (previousValue != null && currentValue < previousValue && !this.disciplineChanged) {
        this.isAnimated = false
        this.lineWidth = 0

        setTimeout(() => {
          this.isAnimated = true
          this.lineWidth = this.getProgress()
        }, 500)
      } else {
        this.lineWidth = this.getProgress()
      }
    },
    disciplineChanged(): void {
      if (this.disciplineChanged) this.isAnimated = false
      else this.isAnimated = true
    },
  },
  created(): void {
    this.lineWidth = this.getProgress()
  },

  methods: {
    getProgress(): number {
      if (this.experience) {
        const lengthPercentage =
          ((this.actual - this.experience) / (this.goal - this.experience)) * 100
        return lengthPercentage > 100 ? 100 : lengthPercentage
      }
      const lengthPercentage = (this.actual / this.goal) * 100
      return lengthPercentage > 100 ? 100 : lengthPercentage
    },
  },
})
</script>

<style lang="scss" scoped>
.progress-bar-main {
  @if $isWsm {
    transform: skewX(-15deg);
  }

  &-line {
    @if $isWsm {
      background: linear-gradient(to right, #f6db26, #ffef7b);
    }
    @if $isSsm {
      background: linear-gradient(to right, #feb942, #ffc868);
    }
  }

  .width-animation {
    transition: width 2s;
  }

  .progress-bar-counts {
    position: absolute;
    width: 100%;

    @if $isWsm {
      transform: skewX(15deg);
    }

    .progress-bar-left-count {
      left: -12rem;
      width: 10rem;
      text-align: right;
    }

    .progress-bar-right-count {
      right: -11rem;
      width: 10rem;
      text-align: left;
    }

    .progress-status-bottom {
      left: 50%;
      transform: translateX(-50%);
      bottom: -3rem;
      width: 100%;

      &-icon {
        display: inline-block;
        margin-left: 0.7rem;
      }
    }
  }
}
</style>
