<template>
  <div id="instructions" class="instructions app-page-wrapper absolute">
    <menu-component menu-type="instructions" :title="$t('map.instructions')" />
    <section class="w-full h-full safe-area">
      <main>
        <app-scrollbar width="100%" height="53rem" scroll="y" slide="y">
          <show-more-content
            v-for="(option, i) in texts"
            :key="i"
            :title="getTitle(option)"
            :description-type="2"
            :desc-data="option.descriptions"
            :is-facebook="option.isFacebook"
          />
        </app-scrollbar>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ShowMoreContent from '@/components/ShowMoreContent.vue'
import { defineComponent } from 'vue'

interface InstructionText {
  title: string
  untranslatableTitle?: boolean
  isFacebook?: boolean
  descriptions: {
    descTitle?: string
    descTitle2?: string
    descText?: string
    descText1?: string
    descText2?: string
    replaceDesc?: string | number
    replaceDesc1?: string | number
  }[]
}

interface ComponentData {
  texts: InstructionText[]
}

export default defineComponent({
  name: 'InstructionsComponent',
  components: {
    ShowMoreContent,
  },
  data(): ComponentData {
    return {
      texts: [
        {
          title: 'info.howToStart',
          descriptions: [
            {
              descTitle: 'map.careerName',
              descText: 'minigame.hint-0-7',
            },
            {
              descTitle: 'minigame.training',
              descText: 'info.startTrening',
            },
            {
              descTitle: 'trainingHall.trainer',
              descText: 'minigame.hint-0-5',
            },
            {
              descTitle: 'benefits.attributes',
              descText: 'info.attributesDesc',
            },
            {
              descTitle: 'common.seasonalAttributes',
              descText: 'info.seasonalAttributesDesc',
            },
            {
              descTitle: 'benefits.arena',
              descText: 'arena.competitionsAbout',
              descText1: 'info.arenaDesc',
              replaceDesc: 30,
            },
            {
              descTitle: 'map.reports',
              descText: 'info.reportsDesc',
              replaceDesc: 72,
            },
            {
              descTitle: 'map.energy',
              descText: 'info.energyDesc',
              descText1: 'info.energyDesc1',
            },
            {
              descTitle: 'map.starts',
              descText: 'info.startsDesc',
              descText1: 'info.startsDesc1',
            },
          ],
        },
        {
          title: 'rankingsGroup.rankings',
          descriptions: [
            {
              descTitle: 'rankingsGroup.rankingPoints',
              descText: 'info.rankingsPointsDesc',
            },
            {
              descTitle: 'rankingsGroup.season',
              descText: 'season.everyMonthSeason',
              descText1: 'season.endSeasonRules',
              descText2: 'season.seasonReward',
            },
            {
              descTitle: 'info.rankingsDisciplines',
              descText: 'info.rankingsDisciplinesDesc',
            },
          ],
        },
        {
          title: 'map.shopName',
          descriptions: [
            {
              descTitle: 'common.equipment',
              descText: 'equipmentShop.equipmentAbout',
            },
            {
              descTitle: 'equipmentShop.equipmentLevels',
              descText: 'equipmentShop.upgradeAbout',
              replaceDesc: 5,
            },
            {
              descTitle: 'equipmentShop.addOns',
              descText: 'equipmentShop.addonsAbout',
            },
            {
              descTitle: 'equipmentShop.durability',
              descText: 'equipmentShop.durabilityAbout',
            },
          ],
        },
        {
          title: 'map.benefits',
          descriptions: [
            {
              descText: 'benefits.about',
              descText1: 'info.benefitsDesc',
            },
          ],
        },
        {
          title: 'rankingsGroup.clubs',
          descriptions: [
            {
              descTitle: 'info.memebership',
              descText: 'info.memebershipDesc',
            },
            {
              descTitle: 'benefits.clubs',
              descText: 'info.clubsDesc',
              descText1: 'info.clubsDesc1',
              descText2: 'info.clubsDesc2',
              replaceDesc: 10,
              replaceDesc1: 72,
            },
            {
              descTitle: 'info.clubCenter',
              descText: 'info.clubCenterDesc',
            },
          ],
        },
        {
          title: 'common.research',
          descriptions: [
            {
              descTitle: 'info.abilities',
              descText: 'info.abilitiesDesc',
            },
            {
              descTitle: 'info.reaserchPoints',
              descText: 'info.reaserchPointsDesc',
              replaceDesc: 'Grand Prize',
            },
            {
              descTitle: 'info.trainingCamps',
              descText: 'info.trainingCampsDesc',
            },
          ],
        },
        {
          title: 'map.starOfTheDayName',
          descriptions: [
            {
              descTitle: 'map.starOfTheDayName',
              descText: 'info.starOfTheDayDesc',
              replaceDesc: 30,
            },
            {
              descTitle: 'benefits.dailyLeague',
              descText: 'info.dailyLeagueDesc',
              descText1: 'info.dailyLeagueDesc1',
            },
            {
              descTitle: 'starOfTheDay.athleteOfTheDay',
              descText: 'info.athleteOfTheDayDesc',
              descText1: 'info.athleteOfTheDayDesc1',
            },
          ],
        },
        {
          title: 'map.galleryName',
          descriptions: [
            {
              descTitle: 'info.images',
              descText: 'info.imagesDesc',
            },
            {
              descTitle: 'info.collection',
              descText: 'info.collectionDesc',
            },
            {
              descTitle: 'info.repeatableImage',
              descText: 'info.repeatableImageDesc',
            },
            {
              descTitle: 'info.specialImage',
              descText: 'info.specialImageDesc',
            },
            {
              descTitle: 'info.galleryPieces',
              descText: 'info.galleryPiecesDesc',
              replaceDesc: 'Grand Prize',
            },
            {
              descTitle: 'info.conventer',
              descText: 'info.conventerDesc',
            },
          ],
        },
        {
          title: 'info.facebookDiscord',
          isFacebook: true,
          descriptions: [
            {
              descText: 'info.facebookDiscordDesc',
              descText1: 'info.facebookDiscordDesc1',
            },
          ],
        },
      ],
    }
  },
  methods: {
    getTitle(prop: InstructionText): string {
      if (prop.untranslatableTitle) return prop.title
      return this.$t(prop.title)
    },
  },
})
</script>

<style lang="scss" scoped>
.instructions {
  main {
    width: 116.25rem;
    position: relative;
    top: 1.375rem;
    margin: 0 auto;
  }
}
</style>
