import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'

import { pathImages } from '@/globalVariables'

export type EventsOsraAtlasLoadDataResponse = () => AtlasDataInterface[]

export const eventsOsraAtlasLoadData: EventsOsraAtlasLoadDataResponse = () => {
  return [
    {
      name: 'buildings.event_osra',
      compressed: true,
      multiatlas: true,
      data: {
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/eventOsraWithGoal/osra-astc8_v6.json?v2',
        },
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/eventOsraWithGoal/osra-etc2rgba_v6.json?v2',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/eventOsraWithGoal/osra_v6.json?v2',
        },
      },
    },
    {
      name: 'buildings.event_osra_outline',
      multiatlas: true,
      compressed: true,
      data: {
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/eventOsraWithGoal/osra-outline-astc8_v6.json?v2',
        },
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/eventOsraWithGoal/osra-outline-etc2rgba_v6.json?v2',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/eventOsraWithGoal/osra-outline_v6.json?v2',
        },
      },
    },
  ]
}
