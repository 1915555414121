<template>
  <section class="flex flex-col clubs-stadium-income relative">
    <header>
      <p class="text-36 uppercase text-texts-standard-default">
        {{ $t('club.arenaIncome') }}
      </p>
    </header>
    <main class="flexing relative">
      <div class="clubs-stadium-income-left flexing">
        <img
          class="clubs-stadium-income-img"
          :src="pathImages + 'clubs/components/stadium/IMG_MONEY.avif'"
        />
      </div>
      <div class="clubs-stadium-income-right flexing flex-col">
        <section v-if="rewardLevel === 1" class="flexing flex-col">
          <app-icon icon-name="lock-lg" />
          <p class="text-texts-standard-default text-36 my-4" v-html="getNoIncomeText" />
        </section>
        <section v-else>
          <p class="text-36 font-bold uppercase text-texts-standard-default">
            {{ $t('club.regularIncome') }}
          </p>
          <div class="clubs-stadium-income-box">
            <clubs-periodic-payment-box
              :reward-value="rewardValue"
              :reward-type="rewardType"
              :reward-interval="rewardInterval"
              :reward-size="30"
              :reward-icon-size="48"
            />
          </div>
          <div v-if="seconds && seconds > 0" class="clubs-stadium-income-time-btn">
            <app-timer-progressbar
              :time-max="rewardInterval"
              :time-remaining="seconds"
              @timer-action="endTimer"
            />
          </div>
          <app-multi-button1
            v-else-if="rewardToClaim > 0"
            btn-id="clubs-stadium-income-btn"
            btn-theme="confirm"
            :btn-text="$t('common.claim')"
            :btn-count="rewardToClaim"
            :is-resources-status-colored="false"
            @click="claimIncome()"
          >
            <template #rightPart>
              <app-main-icon icon-name="money" :icon-size="56" />
            </template>
          </app-multi-button1>
        </section>
      </div>
    </main>
  </section>
</template>

<script lang="ts">
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import AppTimerProgressbar from '@/components/GlobalComponents/AppTimerProgressbar.vue'
import { pathImages } from '@/globalVariables'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { type ClubRewardResponse } from '@/interfaces/clubs/ClubRewards'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ClubsStadiumIncome',
  components: {
    ClubsPeriodicPaymentBox,
    AppTimerProgressbar,
  },
  props: {
    rewardValue: {
      type: Number,
      required: true,
    },
    rewardType: {
      type: String,
      default: 'money',
    },
    rewardInterval: {
      type: Number,
      required: true,
    },
    rewardLevel: {
      type: Number,
      required: true,
    },
    seconds: {
      type: Number,
      default: 0,
    },
  },
  emits: ['claim'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useBuildingsStore, {
      getBuildingsIds: 'getBuildingsIds',
    }),
    ...mapState(useRewardStore, ['getAllClubRewards']),

    rewardToClaim(): number {
      const reward = this.getAllClubRewards.find(
        (rewardData: ClubRewardResponse): boolean =>
          rewardData.building_type.building_type_id === this.getBuildingsIds.clubArena,
      )
      return reward.rewards.value
    },
    getNoIncomeText(): string {
      return this.$replacePlaceholder(
        this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(
            this.$t('club.clubsArenaNoIncome'),
            '{level}',
            '{b} {level} {/b}',
          ),
          'b',
          'text-texts-standard-important',
        ),
        '{level}',
        2,
      )
    },
  },

  methods: {
    ...mapActions(useRewardStore, ['loadAllClubRewards']),
    claimIncome(): void {
      this.$emit('claim', true)
    },
    endTimer(): void {
      this.loadAllClubRewards(true)
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-stadium-income {
  width: 100%;
  height: 100%;

  & header {
    width: 100%;
    height: 4.375rem;
    line-height: 4.375rem;
    @if $isWsm {
      background: linear-gradient(to left, transparent, #144a73, transparent);
    }
    @if $isSsm {
      background: linear-gradient(to left, transparent, rgba(121, 127, 181, 0.99), transparent);
    }
  }

  & main {
    width: 100%;
    height: 100%;
  }

  &-left,
  &-right {
    width: 40%;
    height: 100%;
  }

  &-box {
    width: 19.5rem;
    height: 4.063rem;
    margin: 1rem auto 3rem;
  }

  &-time-btn {
    width: 23.813rem;
  }

  &-btn > :last-child span {
    width: 6rem;
  }

  &-btn > :last-child div {
    position: relative;
    top: -0.25rem;
  }

  &-img {
    height: 17.938rem;
  }
}
</style>
