import { ARENA_DISCIPLINE_ID } from '@/globalVariables'
import { getIsMobileLocalStorage, musicTheme, sendToFlutter } from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useIframeStore } from '@/store/pinia/iframeStore'
// import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { useAccountStore } from '@/store/pinia/accountStore'
import { getValidationToken } from './tokenValidation'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import type { Router, RouteRecordName } from 'vue-router'
import { isMobile } from '@/plugins'
import { ApiService } from '@/services/ApiService'

interface MiniGameData {
  params: {
    mode: number
    token?: string
    isMobileApp?: number
    discipline_id: number
    tutorial_id?: string
    quest_id?: number
    multiplier?: string
    redirect?: RouteRecordName
    app_redirect?: number
    in_iframe?: number
    tournament_id?: number
    tournament_type?: string | null
  }
}

export const openMiniGame = (
  disciplineUrl: string,
  data: MiniGameData,
  router: Router,
): Promise<boolean> => {
  const useCore = useCoreStore()
  const renderQueueStore = useRenderQueueStore()
  useCore.setIsMinigame(true)
  renderQueueStore.addToRenderQueue(RenderQueueComponent.Minigame)

  const disciplineId = data.params.discipline_id
  if (disciplineId && disciplineUrl) {
    disciplineUrl = disciplineUrl.replace('%s', disciplineId.toString())
  } else {
    return
  }

  const url = new URL(disciplineUrl)
  const isMultiWebView = localStorage.getItem('isMultiWebView')
  if (isMultiWebView || isMobile()) {
    data.params = {
      ...data.params,
      token: getValidationToken('token'),
      isMobileApp: getIsMobileLocalStorage(),
      app_redirect: 1,
    }
  } else {
    data.params = {
      ...data.params,
      token: getValidationToken('token'),
      isMobileApp: getIsMobileLocalStorage(),
      in_iframe: 1,
    }
  }
  for (const info in data.params) {
    // prechadzame objekt a pridavame jeho zaznamy do parametrov URLky
    url.searchParams.append(info, data.params[info])
  }

  if (musicTheme && musicTheme.playing() && !isMobile()) {
    musicTheme.stop()
  }
  // eslint-disable-next-line no-useless-escape
  const disableMusic = '{\r\n  "event": "setMusic",\r\n "enabled": false\r\n}'
  sendToFlutter(disableMusic)
  // if (isMobile()) {
  //   usePhaserGameIntegrationStore().pauseScene()
  // }
  if (router.currentRoute.value.query.reloadData) {
    router.replace({
      ...router.currentRoute,
      query: {
        reloadData: undefined,
      },
    })
  }
  const openMinigame = `{\r\n  "event": "openMinigame",\r\n "url": "${url.href}"\r\n}`
  if (isMultiWebView || isMobile()) {
    sendToFlutter(openMinigame)
  } else {
    const useIframe = useIframeStore()
    useIframe.setIframeUrl(url.href)
    useIframe.setIframe(true)
  }
  return Promise.resolve(true)
  // window.open(url.href, '_self')
}

export interface FbResponse {
  status: 'authorization_expired' | 'connected' | 'not_authorized' | 'unknown'
  authResponse: {
    accessToken?: string
    data_access_expiration_time?: number
    expiresIn: number
    signedRequest?: string
    userID: string
    grantedScopes?: string
    reauthorize_required_in?: number
    code?: string
  }
  error?: unknown
}

export const logoutUser = (): void => {
  // odhlasenie pouzivatela, vymaze sa localstorage a presmeruje sa na login
  const careerLeftBoxLS = localStorage.getItem('careerLeftBox')
  const isFlutterLS = localStorage.getItem('is_flutter')
  const isMobileLS = localStorage.getItem('is_mobile')
  useAccountStore().clearConnectedAccounts()
  ApiService.$abort()
  ApiService.$clear()
  localStorage.clear()
  localStorage.setItem('careerLeftBox', careerLeftBoxLS)
  if (isFlutterLS) {
    localStorage.setItem('is_flutter', isFlutterLS)
  }
  if (isMobileLS) {
    localStorage.setItem('is_mobile', isMobileLS)
  }
  if (sessionStorage.getItem('hideDeleteAccountMessage'))
    sessionStorage.removeItem('hideDeleteAccountMessage')
  if (sessionStorage.getItem('isLoggedFromAdmin')) {
    close()
    return
  }
  if (window.FB) {
    window.FB.getLoginStatus((response: FbResponse): void => {
      if (response && response.status === 'connected') {
        window.FB.logout()
      }
    })
  }
  if (!isMobile()) {
    location.href = '/login'
  }
}

export const setArenaDisciplineId = (id: number): void => {
  if (!id) return

  localStorage.setItem(ARENA_DISCIPLINE_ID, id.toString())
}

export const getArenaDisciplineId = (): number | null => {
  const id = parseInt(localStorage.getItem(ARENA_DISCIPLINE_ID))
  return typeof id === 'number' && !isNaN(id) ? id : null
}
