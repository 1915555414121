import { isCanvasInactive, sendToFlutter } from '@/helpers'
import { InteractiveObject } from '../abstractClasses'
import type {
  ConnectAccountOsraConfigInterface,
  ExclamationNotificationConfigInterface,
} from '@/map-phaser-new/interfaces'
import { ExclamationNotification } from '@/map-phaser-new/models'
import { cameraHandler } from '@/map-phaser-new/utils'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { isMobile } from '@/plugins'
import router from '@/router'

export class ConnectAccountOsra extends InteractiveObject {
  private osraImage: Phaser.GameObjects.Image
  private osraOutlineImage: Phaser.GameObjects.Image
  private osraNotification: ExclamationNotification

  constructor(positionX: number, positionY: number) {
    super(positionX, positionY)
  }

  public addToMap(
    activeScene: Phaser.Scene,
    baseConfig: ConnectAccountOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    this.addOsraImage(activeScene, baseConfig)
    // WSM doesn't have outlineConfig.
    if (baseConfig.outlineConfig) {
      this.addOsraOutlineImage(activeScene, baseConfig)
      this.addOsraHoverListener()
    }
    this.addOsraClickListener(baseConfig.appMessage)

    this.osraNotification = new ExclamationNotification(
      baseConfig.notificationX,
      baseConfig.notificationY,
    )
    this.osraNotification.addToMap(activeScene, notificationConfig)
  }

  private addOsraImage(
    activeScene: Phaser.Scene,
    baseConfig: ConnectAccountOsraConfigInterface,
  ): void {
    if (this.osraImage) return

    this.osraImage = activeScene.add
      .image(this.positionX, this.positionY, baseConfig.textureKey)
      .setDepth(baseConfig.depth)
      .setOrigin(baseConfig.origin)
      .setInteractive({ useHandCursor: baseConfig.useHandCursor })
      .setName(baseConfig.textureKey)
  }

  private addOsraOutlineImage(
    activeScene: Phaser.Scene,
    baseConfig: ConnectAccountOsraConfigInterface,
  ): void {
    if (this.osraOutlineImage) return
    const xPosition = baseConfig.outlineConfig.xCorrectionValue
      ? this.positionX + baseConfig.outlineConfig.xCorrectionValue
      : this.positionX
    const yPosition = baseConfig.outlineConfig.yCorrectionValue
      ? this.positionY + baseConfig.outlineConfig.yCorrectionValue
      : this.positionY
    this.osraOutlineImage = activeScene.add
      .image(xPosition, yPosition, baseConfig.outlineConfig.textureKey)
      .setDepth(baseConfig.outlineConfig.depth)
      .setOrigin(baseConfig.origin)
      .setVisible(false)
      .setName(baseConfig.outlineConfig.textureKey)
  }

  private addOsraClickListener(message: string): void {
    this.osraImage.on(
      'pointerup',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        if (cameraHandler.isDragging() || isCanvasInactive(event)) return

        if (useTutorialStore().getIsTutorial) return

        if (isMobile()) {
          sendToFlutter(message.toString())
        } else {
          router.push({ name: 'CrossAccountLinkingWeb' })
        }
      },
    )

    this.osraImage.on(
      'pointerdown',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        if (isCanvasInactive(event)) return

        cameraHandler.setIsDrag(false)
      },
    )
  }

  private addOsraHoverListener(): void {
    this.osraImage.on('pointerover', (): void => {
      this.osraOutlineImage.setVisible(true)
    })

    this.osraImage.on('pointerout', (): void => {
      this.osraOutlineImage.setVisible(false)
    })
  }

  private removeOsra(): void {
    if (!this.osraImage) return

    this.osraImage.removeAllListeners()
    this.osraImage.destroy()
    this.osraImage = null
  }

  private removeNotification(activeScene: Phaser.Scene): void {
    if (!this.osraNotification) return

    this.osraNotification.removeFromMap(activeScene)
    this.osraNotification = null
  }

  public removeOsraAndNotification(activeScene: Phaser.Scene): void {
    this.removeNotification(activeScene)
    this.removeOsra()
  }
}
