<template>
  <div
    v-if="disciplines.length > 0"
    class="choose-discipline app-page-wrapper absolute"
    data-cy="choose-discipline"
  >
    <section class="w-full h-full flex flex-col justify-center safe-area">
      <modal-head class="choose-discipline-head" :show-info="false" :is-icon-hidden="true">
        <p class="text-texts-standard-default text-50 font-bold uppercase z-1">
          {{ $t('discipline.chooseDiscipline') }}
        </p>
        <div
          v-if="isMobileLocalStorage"
          class="choose-discipline-registred"
          @click="alreadyRegistered()"
        >
          <p class="text-texts-standard-important text-36 z-1 underline">
            {{ $t('map.already_registered') }}
          </p>
        </div>
      </modal-head>
      <div class="choose-discipline-content">
        <div
          class="discipline-box-wrapper"
          :class="{ 'discipline-box-wrapper--mobile': $isMobile() }"
        >
          <div
            v-for="(id, n) in disciplines"
            id="discipline-box"
            :key="n"
            :class="[
              'discipline-box',
              {
                selected: selectedDiscipline === id || firstUnlockedDisciplineId === id,
                lock: lockedDisciplineId === id || firstUnlockedDisciplineId,
              },
            ]"
            :style="{
              backgroundImage: `url(${pathImages}disciplines/${id}-disciplines${id === 2 ? '-ibu' : ''}.avif?v1)`,
            }"
            @click.stop="selectItem(id)"
          >
            <div class="discipline-box-header relative flex items-start">
              <div class="discipline-box-header-icon-wrapper flexing choose-discipline-revert-skew">
                <app-discipline-icon :discipline-id="id" :size="70" />
              </div>
              <div
                class="discipline-box-header-heading-wrapper flex justify-center relative flex-col w-full text-left"
              >
                <shrink-text
                  :text="$translateDiscipline(id)"
                  container-class-list="discipline-box-header-heading-wrapper__text revert-skew"
                  :text-class-list="[
                    'uppercase',
                    {
                      'text-texts-standard-additional font-bold': $isWsm,
                      'text-texts-standard-default font-semibold': $isSsm,
                    },
                  ]"
                  :font-size="40"
                />
                <div class="ml-2 yellow-strip" />
              </div>
            </div>

            <div
              v-if="lockedDisciplineId === id"
              class="discipline-box-body w-full"
              :class="['disc-' + id, { lock: lockedDisciplineId === id }]"
            >
              <div class="flexing w-full h-full relative">
                <div class="discipline-box-body-lock-lock">
                  <p
                    class="text-texts-standard-additional text-36 uppercase"
                    :class="{
                      'font-medium': $isSsm,
                    }"
                  >
                    {{ lockedText }}
                  </p>
                </div>
              </div>
            </div>

            <video
              :id="`video-player-${id}`"
              class="video-player"
              playsinline
              loop
              muted
              poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAArEAAAKxAFmbYLUAAAADUlEQVQImWP4//8/AwAI/AL+hc2rNAAAAABJRU5ErkJggg=="
            >
              <source type="video/mp4" />
              <source type="video/webm" />
              <img
                :src="`${pathImages}disciplines/${id}-disciplines-${id === 2 ? '-ibu' : ''}.avif?v1`"
                title="Your browser does not support the <video> tag"
                alt="video"
              />
            </video>
          </div>
        </div>
        <footer>
          <div class="footer-info-panel">
            <info-text
              :text="$t('discipline.nextDisciplinesUnlock')"
              :text-size="32"
              :class="{ uppercase: $isWsm, 'font-medium': $isSsm }"
            />
          </div>
          <arrow-animation
            position="right"
            border="button"
            :tutorial="isChooseFirstDisciplineContinue"
          >
            <app-button
              btn-id="choose-discipline-btn"
              :disabled="
                (selectedDiscipline === 0 && !firstUnlockedDisciplineId) ||
                !!firstUnlockedDisciplineId
              "
              :btn-type="'secondary'"
              :btn-text="$t('button.continue')"
              btn-size="md"
              :class="[
                { disabled: selectedDiscipline === 0 && !firstUnlockedDisciplineId },
                actualStageData ? actualStageData.name : '',
              ]"
              :loading="isMinigame"
              @click="$debounce(openGame, [false])"
            />
          </arrow-animation>
        </footer>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import ModalHead from '@/components/ModalHead.vue'
import InfoText from '@/components/InfoText.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import {
  DISCIPLINE_ID,
  gameDisciplinesEndpoint,
  minigameUrl,
  pathImages,
  userDisciplineEndpoint,
} from '@/globalVariables'
import { getIsMobileLocalStorage, openMiniGame, sendToFlutter } from '@/helpers'
import type { UserDisciplineApiResponse } from '@/interfaces/responses/disciplines/UserDisciplineApiResponse'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
interface ComponentData {
  DISCIPLINE_ID: typeof DISCIPLINE_ID
  pathImages: typeof pathImages
  getIsMobileLocalStorage: typeof getIsMobileLocalStorage
  minigameUrl: typeof minigameUrl
  selectedDiscipline: number
  disciplines: number[]
  attFlutterString: string
  login: string
  lockedDisciplineId: number
  lockedText: string
  disableChoose: boolean
}

export default defineComponent({
  name: 'ChooseDiscipline',
  components: {
    ModalHead,
    ArrowAnimation,
    InfoText,
    ShrinkText,
  },
  data(): ComponentData {
    return {
      DISCIPLINE_ID,
      pathImages,
      selectedDiscipline: 0,
      getIsMobileLocalStorage,
      minigameUrl,
      disciplines: [],
      attFlutterString: '{"event":"showATT"}',
      login: '{"event":"login"}',
      lockedDisciplineId: null,
      lockedText: '',
      disableChoose: false,
    }
  },
  computed: {
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useUserStore, {
      getUserId: 'getUserId',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useCoreStore, ['isMinigame']),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
      getDisciplineUnlockLevel: 'getDisciplineUnlockLevel',
      currentGameDisciplinesIds: 'getCurrentGameDisciplinesIds',
    }),
    isChooseFirstDisciplineContinue(): boolean {
      return (
        (this.actualStageData?.name === 'chooseFirstDisciplineReturnFromMinigame' ||
          this.actualStageData?.name === 'chooseFirstDisciplineContinue') &&
        !!this.selectedDiscipline
      )
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },
  async created(): Promise<void> {
    this.sendToFlutter(this.attFlutterString)
    await this.loadInitialDisciplines()
    this.lockedText = this.$replacePlaceholder(
      this.$t('research.availableFrom'),
      '%s',
      this.getDisciplineUnlockLevel(this.lockedDisciplineId ?? 0).toString(),
    )
  },
  methods: {
    openMiniGame,
    sendToFlutter,
    ...mapActions(useCoreStore, ['postError', 'setLoadingScreen']),
    ...mapActions(useTutorialStore, ['logTutorialProgress']),
    ...mapActions(useDisciplineStore, {
      loadDisciplines: 'loadDisciplines',
    }),
    async loadInitialDisciplines(): Promise<void> {
      try {
        this.disciplines = await this.$axios.get<{}, number[]>(gameDisciplinesEndpoint + 'initial')
        await this.loadDisciplines()
        this.lockedDisciplineId = this.currentGameDisciplinesIds[3] ?? 11
        this.disciplines.push(this.lockedDisciplineId)
        this.setLoadingScreen(false)
      } catch (error: unknown) {
        this.postError({
          type: 'global-js-error',
          message: JSON.stringify({
            text: 'Mame problem na loadingu aplikacie pri tahani disciplin',
            error: `Message: ${error.message}, Error: ${error}`,
          }),
        })
      }
    },
    selectItem(id: number): void {
      if (id === this.lockedDisciplineId || this.disableChoose) return

      if (id !== this.selectedDiscipline) {
        const player = document.getElementById(`video-player-${id}`) as HTMLVideoElement

        if (player.canPlayType('video/mp4; codecs="avc1.42E01E"') === 'probably') {
          player.src = `${pathImages}videos/discipline-video-${id}.mp4`
        } else {
          player.src = `${pathImages}videos/discipline-video-${id}.webm?v1`
        }
        if (player) {
          const playPromise = player.play()
          playPromise.catch((error) => {
            console.error(error)
          })
        }
        if (this.selectedDiscipline) {
          const previousPlayer = document.getElementById(
            `video-player-${this.selectedDiscipline}`,
          ) as HTMLVideoElement
          if (previousPlayer) {
            previousPlayer.src = ''
          }
        }
      }

      this.selectedDiscipline = id
    },
    async openGame(): Promise<void> {
      this.disableChoose = true
      if (!this.firstUnlockedDisciplineId) {
        try {
          await this.$axios.put<{}, UserDisciplineApiResponse>(userDisciplineEndpoint, {
            games_disciplines_id: this.selectedDiscipline,
          })

          if (this.actualStageData?.name === 'chooseFirstDiscipline') {
            await this.logTutorialProgress(this.actualStageData.name)
          }
        } catch (error: unknown) {
          console.error(error)
          this.postError({
            type: 'global-js-error',
            message: JSON.stringify({
              text: 'Mame problem na put operacii chosediscipline',
              error: `Message: ${error.message}, Error: ${error}`,
            }),
          })
          return
        }
      }
      this.loadDisciplines()

      await this.openMiniGame(
        this.minigameUrl,
        {
          params: {
            mode: this.mode.tutorial,
            discipline_id: this.firstUnlockedDisciplineId ?? this.selectedDiscipline,
            tutorial_id: this.actualStageData?.name ?? '',
          },
        },
        this.$router,
      )
      this.logTutorialProgress('chooseFirstDisciplineReturnFromMinigame')
    },
    alreadyRegistered(): void {
      this.postError({
        type: 'already-registered-clicked',
        message: `Already registered ${this.getUserId}`,
      })
      this.sendToFlutter(this.login)
    },
  },
})
</script>

<style lang="scss" scoped>
.choose-discipline {
  &-head {
    margin: 2% auto;
    margin-top: 0;
    height: 8%;
  }

  &-registred {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  &-revert-skew {
    @if $isWsm {
      transform: skew(4deg);
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 50rem;
  }

  .discipline-box {
    position: relative;
    width: 28.3125rem;
    height: 42.553rem;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    overflow: hidden;
    cursor: pointer;

    @if $isWsm {
      outline: 0.125rem solid #3f6b93;
      transform: skew(-4deg);
    }

    @if $isSsm {
      outline: 0.125rem solid #636790;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 118rem;
      margin: 0 auto;

      &--mobile {
        width: 124rem;
      }
    }

    &-header {
      background-size: 100% 100%;
      height: 4.5rem;
      position: relative;
      z-index: 1;
      margin-bottom: 0.1rem;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          rgb(21 78 121 / 80%) 50%,
          rgb(11 44 79 / 20%) 100%
        );
      }

      @if $isSsm {
        background-color: rgba(29, 30, 43, 0.9);
      }

      @if $isWsm {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url($path-backgrounds + 'discipline-box-header-bg.png') no-repeat;
          background-size: 100% 100%;
          opacity: 0.7;
        }
      }

      &-icon {
        &-wrapper {
          width: 4.375rem;
          height: 4.375rem;
          position: relative;
          // margin-left: due to the dynamic range of the text wrapper
          margin-left: 0.8rem;
          top: 0.563rem;
        }
      }

      &-heading {
        &-wrapper {
          height: 3.8rem;
          position: relative;
          margin: 0;
          top: 0;
          padding: 0.313rem 1.188rem 0;
          // must have
          min-width: 0;

          &__text {
            height: 100%;
          }

          .yellow-strip {
            position: absolute;
            bottom: -0.25rem;

            @if $isWsm {
              width: 90%;
              background-image: linear-gradient(
                to right,
                transparent 0%,
                #fbe36e 20%,
                #fbe36e 60%,
                transparent 100%
              );
            }

            @if $isSsm {
              width: 15.625rem;
              height: 0.25rem;
              background-color: theme('colors.texts.standard.important');
              background-image: none;
            }
          }
        }
      }
    }

    &-body {
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;

      &.lock {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          @if $isWsm {
            background-color: rgba(11, 30, 53, 0.8);
          }

          @if $isSsm {
            background-color: rgba(59, 67, 74, 0.8);
          }
        }

        .discipline-box-body-lock-lock {
          min-width: 10rem;
          height: 2.313rem;
          line-height: 2.313rem;

          @if $isWsm {
            padding: 0 1.25rem;
            border-left: 0.125rem solid #377fb8;
            border-right: 0.125rem solid #377fb8;
          }

          @if $isSsm {
            padding: 0 0.5rem;
            border-left: 0.125rem solid #cb3d7e;
            border-right: 0.125rem solid #cb3d7e;
          }
        }
      }
    }

    &.selected {
      @if $isWsm {
        outline: 0.25rem solid #fadd1e;

        .discipline-box-body {
          box-shadow: inset 0rem 0rem 2rem 0rem #fadd1e;
        }
      }

      @if $isSsm {
        outline: 0.25rem solid theme('colors.texts.standard.important');
      }
    }

    * {
      pointer-events: none;
    }

    &.lock {
      pointer-events: none;
    }
  }

  footer {
    margin: 0 auto;
    width: 96%;
    display: flex;
    justify-content: space-between;
    position: absolute;

    @if $isWsm {
      bottom: 0;
    }

    @if $isSsm {
      bottom: -2.5rem;
    }

    .footer-info-panel {
      width: 70rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-text {
        width: 92%;
        display: flex;
        text-align: left;
      }
    }

    #choose-discipline-btn {
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .video-player {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    transform: scale(1.2);
  }
}

.icon-tune {
  pointer-events: none;
  cursor: initial;
}
</style>
