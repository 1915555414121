<template>
  <div
    v-if="!isContentLoading"
    class="component-discipline m-auto flexing bordered-lg"
    :class="{ 'is-event-view': isEventView }"
  >
    <arrows-component
      v-if="!isEventView"
      :array-of-ids="!isTrainer ? unlockDisciplinesIds : trainerDisciplinesIds"
    />
    <div
      class="discipline-left"
      :style="{
        backgroundImage: `url(${pathImages}disciplines/${discipline.id}-training-detail${discipline.id === 2 ? '-ibu' : ''}.avif?v1)`,
      }"
    >
      <event-discipline-badge
        :type="TRAINING_HALL"
        :active-discipline-id="activeDisciplineId"
        :is-event-view="isEventView"
      />

      <div class="discipline-left-header flex items-center">
        <app-discipline-icon class="ml-1" :discipline-id="Number(discipline.id)" />
        <div class="discipline-left-header-text">
          <p class="font-bold uppercase text-texts-standard-additional text-40">
            {{ $translateDiscipline(discipline.id) }}
          </p>
        </div>
      </div>
      <section class="discipline-left-bottom absolute bottom-0 w-full">
        <div class="discipline-left-bottom-section bottom-2">
          <div class="discipline-left-bottom-section-header">
            <p class="font-bold text-texts-standard-default text-32">
              {{ $t('trainingHall.attributeValue') }}
            </p>
          </div>
          <div class="discipline-left-bottom-section-middle flexing">
            <p
              v-tippy="{
                theme: 'WSM',
                content: $replacePlaceholder(
                  $t('trainingHall.attributeInfo'),
                  '%s',
                  String(MAX_ATTRIBUTE_VALUE),
                ),
                placement: 'right',
                textAlign: 'center',
              }"
              :class="{ 'bounce-text': bounce }"
              class="font-bold text-texts-standard-important text-60 attributeText"
            >
              {{ discipline.userAttributes?.baseSeasonal.value }}
            </p>
            <div
              class="attributeIcon"
              :class="'discipline icon-discipline-' + discipline.id + '-light-48'"
            />
          </div>
          <div
            v-if="discipline.userAttributes"
            class="discipline-left-bottom-section-footer flexing"
          >
            <div class="base-wrapper flexing">
              <p>
                {{ $t('trainingHall.base') }}:
                <b class="ml-1">
                  {{ discipline.userAttributes?.base?.value ?? 0 }}
                </b>
              </p>
            </div>
            <div class="middle-line" />
            <div class="seasonal-wrapper flexing">
              {{ $t('trainingHall.seasonal') }}:
              <div class="base-number font-bold text-texts-standard-important ml-1">
                {{ discipline.userAttributes?.seasonal?.value ?? 0 }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- TODO: refactor to smaller components -->
    <div class="discipline-right">
      <div class="discipline-right-header flex items-center">
        <div class="discipline-right-header-text flex items-center">
          <p class="text-texts-standard-default text-32">{{ $t('trainingHall.bestScore') }}:</p>
          <b class="text-texts-standard-important text-36 ml-1">
            {{ discipline.mechanicStats?.bestScore }}
          </b>
          <app-main-icon icon-name="training_points" :icon-size="48" />
        </div>
        <div class="discipline-right-header-level flex items-center">
          <span class="discipline-right-header-level-text">
            <p class="text-texts-standard-default text-32">
              {{ $t('trainingHall.trainingLevel') }}:
            </p>
          </span>
          <div
            v-tippy="{
              theme: 'WSM',
              content: $t('trainingHall.trainingLevelInfo'),
              placement: 'left',
              textAlign: 'center',
            }"
            class="badge-level"
          >
            {{ discipline.mechanicStats?.disciplineLevel }}
          </div>
        </div>
      </div>
      <div class="discipline-right-section relative">
        <slot name="section-header" />
        <div class="discipline-right-section-header flexing">
          <p class="text-texts-standard-default text-32">
            {{ $t('trainingHall.trainingProgress') }}
          </p>
        </div>
        <transition name="progress">
          <app-progress-bar
            v-show="!change"
            :class="getProgressClass"
            :bar-width="31.375"
            :bar-height="1.625"
            :counts="true"
            :text-size="2.25"
            :actual="progressActual"
            :goal="discipline.trainingPointsTarget"
            :counts-actual="discipline.userAttributes?.baseSeasonal.value"
            status-icon="training_points"
            :discipline-changed="isDisciplineChanged"
            :show-bottom-status="!discipline.buildingLimitReached"
            :locked-icon="discipline.buildingLimitReached"
          />
        </transition>
        <transition name="update-number" @after-enter="attributeAnimation">
          <p
            v-show="change"
            class="upgrade-number text-texts-standard-important font-bold absolute"
          >
            {{ discipline.userAttributes?.baseSeasonal.value }}
          </p>
        </transition>
        <p
          v-if="discipline.buildingLimitReached"
          class="text-texts-standard-important text-28 absolute top-40 w-full"
        >
          {{ $t('trainingHall.attributeLimitReached') }}
        </p>
        <slot
          name="section-upgrade-attribute-btn"
          :is-button-disabled="isSimulation || isButtonDisabled !== PlayButtonLock.Unset"
        />
      </div>
      <div class="discipline-right-footer flexing">
        <template v-if="discipline.state === DISCIPLINE_TRAINED">
          <p class="text-32 text-texts-standard-positive flexing">
            {{ $t('trainingHall.trainingCompleted') }}
          </p>
        </template>
        <template v-else>
          <arrow-animation
            position="right"
            border="button"
            :tutorial="actualStage && actualStage.name === 'returnFromTrainingLive'"
          >
            <app-multi-button
              v-if="!(isGroupB && !isTutorial && !isTrainer)"
              v-tippy="getTippyContent(true)"
              btn-id="training-hall-start-live"
              btn-type="secondary"
              btn-size="sm"
              :btn-text="$t('button.start')"
              class="inline-flex align-top mr-4"
              :disabled="
                isSimulation || isButtonDisabled !== PlayButtonLock.Unset || isAttributeUpgrading
              "
              :loading="isMinigame || isButtonDisabled === PlayButtonLock.Live"
              :is-cool-down="isCoolDown"
              @click="isCoolDown ? null : onButtonClick(true, true)"
            >
              <template #rightPart>
                <div class="flex items-center justify-center flex-col leading-none pt-1">
                  <span v-if="isEventPassActive" class="text-texts-standard-default strike text-28">
                    {{ requiredConsumableValue }}
                  </span>
                  <span class="text-texts-standard-default">
                    {{
                      isEventPassActive ? requiredConsumableCostDiscounted : requiredConsumableValue
                    }}
                  </span>
                </div>
                <app-main-icon :icon-size="48" :icon-name="'energy'" />
              </template>
            </app-multi-button>
          </arrow-animation>
          <app-multi-button
            v-tippy="getTippyContent(false)"
            btn-id="training-hall-start-simulation"
            btn-type="primary"
            btn-size="sm"
            :btn-text="$t('button.simulate')"
            class="inline-flex align-top ml-4"
            :disabled="
              isSimulation || isButtonDisabled !== PlayButtonLock.Unset || isAttributeUpgrading
            "
            :loading="isSimulation || isButtonDisabled === PlayButtonLock.Simulation"
            @click="onButtonClick(false, true)"
          >
            <template #rightPart>
              <div class="flex items-center justify-center flex-col leading-none pt-1">
                <span v-if="isEventPassActive" class="text-texts-standard-default strike text-28">
                  {{ requiredConsumableValue }}
                </span>
                <span class="text-texts-standard-default">
                  {{
                    isEventPassActive ? requiredConsumableCostDiscounted : requiredConsumableValue
                  }}
                </span>
              </div>
              <app-main-icon :icon-size="48" :icon-name="'energy'" />
            </template>
          </app-multi-button>
        </template>
      </div>
    </div>
    <parameter-popup v-if="isPopupOpen" parameter="energy" @close="isPopupOpen = false" />
    <skip-tutorial-popup
      v-if="showSkipTutorialPopup"
      :discipline-id="discipline.id"
      :multiplier="activeMultiplicator(Multiplier.Training).name"
      :no-tutorial-mode="miniGameMode"
      @hide-popup="showSkipTutorialPopup = false"
    />
    <info-popup
      v-if="buildingLimitReached"
      :popup-title="$t('trainingHall.attributeLimitReached')"
      @close="buildingLimitReached = false"
    >
      <section class="p-7 pb-0">
        <p class="text-texts-standard-default text-30">
          {{ $t('trainingHall.attributeLimitInfo') }}
        </p>
        <p class="text-texts-standard-default text-30 mb-12">
          {{ $t('trainingHall.attributeLimitLevel') }}
        </p>
        <app-check-box :is-checked="!isConfirmAllowed" @checked-action="disableLimitConfirm()">
          <p class="pl-5 pr-5 cursor-pointer text-texts-standard-default text-30">
            {{ $t('trainingHall.attributeLimitDontDisplay') }}
          </p>
        </app-check-box>
        <div class="flexing mt-12">
          <app-multi-button
            v-if="!(isGroupB && !isTutorial && !isTrainer)"
            v-tippy="getTippyContent(true)"
            btn-id="training-hall-start-live"
            btn-type="secondary"
            btn-size="sm"
            :btn-text="$t('button.start')"
            class="inline-flex align-top"
            :disabled="
              isSimulation || isButtonDisabled !== PlayButtonLock.Unset || isAttributeUpgrading
            "
            :loading="isMinigame || isButtonDisabled === PlayButtonLock.Live"
            :is-cool-down="isCoolDown"
            @click="isCoolDown ? null : onButtonClick(true)"
          >
            <template #rightPart>
              <span class="text-right w-16 text-texts-standard-default">
                {{ requiredConsumableValue }}
              </span>
              <app-main-icon :icon-size="48" :icon-name="'energy'" />
            </template>
          </app-multi-button>
          <app-multi-button
            v-tippy="getTippyContent(false)"
            btn-id="training-hall-start-simulation"
            btn-type="primary"
            btn-size="sm"
            multi-width="23.125rem"
            :btn-text="$t('button.simulate')"
            class="inline-flex align-top mx-4"
            :disabled="
              isSimulation || isButtonDisabled !== PlayButtonLock.Unset || isAttributeUpgrading
            "
            :loading="isSimulation || isButtonDisabled === PlayButtonLock.Simulation"
            @click="isSimulation ? null : onButtonClick(false)"
          >
            <template #rightPart>
              <span class="text-right w-16 text-texts-standard-default">
                {{ requiredConsumableValue }}
              </span>
              <app-main-icon :icon-size="48" icon-name="energy" />
            </template>
          </app-multi-button>
        </div>
      </section>
    </info-popup>
  </div>

  <component-loading height="33.1875rem" :is-loading="isContentLoading" />
</template>

<script lang="ts">
import ArrowsComponent from '@/components/ArrowsComponent.vue'
import EventDisciplineBadge from '@/components/Events/EventDisciplineBadge.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import SkipTutorialPopup from '@/components/SkipTutorialPopup.vue'
import {
  DISCIPLINE_TRAINED,
  ENERGY,
  MAX_ATTRIBUTE_VALUE,
  OFFER_STATES,
  TRAINING_HALL,
  minigameUrl,
  pathImages,
  trainingEndpoint,
  Multiplier,
} from '@/globalVariables'
import { gameToken, openMiniGame, playSound } from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore, type DisciplineStoreState } from '@/store/pinia/disciplinesStore'
import { useOfferStore } from '@/store/pinia/offerStore'
import {
  useResponseTaskStore,
  type UniversalTrainingPointsResponse,
} from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { PlayButtonLock } from '@/interfaces/Buttons'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import ArrowAnimation from '../ArrowAnimation.vue'
import { ApiService } from '@/services/ApiService'
import type { ProgressData } from '@/interfaces/global/Progress'
import type { Nullable } from '@/interfaces/utils'
import type { TrainingApiResponse } from '@/interfaces/responses/training/TrainingApiResponse'

interface ComponentData {
  MAX_ATTRIBUTE_VALUE: typeof MAX_ATTRIBUTE_VALUE
  DISCIPLINE_TRAINED: typeof DISCIPLINE_TRAINED
  TRAINING_HALL: typeof TRAINING_HALL
  openMiniGame: typeof openMiniGame
  pathImages: typeof pathImages
  gameToken: typeof gameToken
  minigameUrl: typeof minigameUrl
  isPopupOpen: boolean
  change: boolean
  bounce: boolean
  upgrade: boolean
  showSkipTutorialPopup: boolean
  buildingLimitReached: boolean
  isConfirmAllowed: boolean
  isDisciplineChanged: boolean
  progressActual: number
  PlayButtonLock: typeof PlayButtonLock
  isButtonDisabled: PlayButtonLock
  Multiplier: typeof Multiplier
}

interface TippyContent {
  theme: string
  content: string
  placement: string
  textAlign: string
  appendTo?: string
}

export default defineComponent({
  name: 'TrainingDiscipline',
  components: {
    ArrowsComponent,
    ArrowAnimation,
    InfoPopup,
    ParameterPopup,
    SkipTutorialPopup,
    EventDisciplineBadge,
  },
  props: {
    miniGameMode: {
      type: Number,
      default: 0,
    },
    discipline: {
      type: Object as PropType<Nullable<DisciplineStoreState['specificDiscipline']>>,
      default: () => null,
    },
    isTrainer: {
      type: Boolean,
      required: true,
    },
    activeDisciplineId: {
      type: Number,
      required: true,
    },
    isEventView: {
      type: Boolean,
      default: false,
    },
    isAttributeUpgrading: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      MAX_ATTRIBUTE_VALUE,
      DISCIPLINE_TRAINED,
      TRAINING_HALL,
      openMiniGame,
      pathImages,
      gameToken,
      minigameUrl,
      isPopupOpen: false,
      change: false,
      bounce: false,
      upgrade: false,
      showSkipTutorialPopup: false,
      buildingLimitReached: false,
      isConfirmAllowed: true,
      isDisciplineChanged: false,
      progressActual: 0,
      PlayButtonLock,
      isButtonDisabled: PlayButtonLock.Unset,
      Multiplier,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
      isGroupB: 'isGroupB',
      isGroupCD: 'isGroupCD',
      showTrainingLimitConfirm: 'showTrainingLimitConfirm',
      eventPassInfo: 'getEventPassInfo',
    }),
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useCoreStore, ['isMinigame', 'isSimulation']),
    ...mapState(useDisciplineStore, {
      unlockDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
      trainerDisciplinesIds: 'getTrainerDisciplinesIds',
    }),
    ...mapState(useResponseTaskStore, {
      userEnergy: 'getEnergy',
      task: 'getProgress',
      universalTrainingPoints: 'getUniversalTrainingPoints',
      getCoolDown: 'getCoolDown',
    }),
    requiredConsumableValue(): number {
      return this.isTrainer
        ? this.discipline.consumableCost
        : this.discipline.consumableCost * this.activeMultiplicator(Multiplier.Training).value
    },
    requiredConsumableCostDiscounted(): number {
      return this.isTrainer
        ? this.discipline.consumableCostDiscounted
        : this.discipline.consumableCostDiscounted *
            this.activeMultiplicator(Multiplier.Training).value
    },
    isCoolDown(): boolean {
      return !this.isTrainer && this.isGroupCD && this.getCoolDown > 0
    },
    disablePlay(): boolean {
      const consumableCost = this.isEventPassActive
        ? this.requiredConsumableCostDiscounted
        : this.requiredConsumableValue

      return this.userEnergy.value < consumableCost
    },
    isContentLoading(): boolean {
      return this.discipline == null
    },
    showReachedLimitPopup(): boolean {
      return this.discipline.buildingLimitReached && this.showTrainingLimitConfirm
    },
    getProgressClass(): string {
      return `training-discipline-progress-${this.isTrainer ? 'trainer' : 'detail'}`
    },
    isEventPassActive(): boolean {
      return this.eventPassInfo.state === OFFER_STATES.active
    },
  },

  watch: {
    discipline(
      newValue: DisciplineStoreState['specificDiscipline'],
      oldValue: DisciplineStoreState['specificDiscipline'],
    ): void {
      if (!newValue) return
      this.progressActual = newValue.userStats?.trainingPoints
      if (newValue?.id === oldValue?.id) {
        // Kontrola ci sa mi zmenila hodnota atributu
        if (
          newValue.userAttributes.base.value !== oldValue?.userAttributes?.base.value &&
          oldValue?.userAttributes?.base.value !== undefined
        ) {
          this.upgrade = true
        } else this.upgrade = false
      }
    },
    task(checkProgress: ProgressData): void {
      // nastavenie animacie po ukonceni progress message
      if (!checkProgress && this.upgrade) {
        this.loadSpecificDiscipline({ disciplineId: this.discipline.id })
        this.bounce = false
        this.change = true
      }
    },
    universalTrainingPoints(instantUpgrade: UniversalTrainingPointsResponse): void {
      if (instantUpgrade.newStat?.value) {
        this.loadSpecificDiscipline({ disciplineId: this.discipline.id })
        this.bounce = false
        this.change = true
      }
    },
    $route(): void {
      this.isDisciplineChanged = true
    },
    isMinigame(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
    isSimulation(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
  },
  beforeUnmount(): void {
    this.progressActual = 0
    this.setSimulation(false)
  },
  methods: {
    ...mapActions(useUserStore, {
      setProfileAttributes: 'setProfileAttributes',
      setUserExperience: 'setUserDataExperience',
      setUserDataTargetExperience: 'setUserDataTargetExperience',
      setUserDataStartingExperience: 'setUserDataStartingExperience',
      setUserDataLevel: 'setUserDataLevel',
    }),
    ...mapActions(useOfferStore, ['setRefreshingBundle']),
    ...mapActions(useCoreStore, ['setSimulation']),
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu', 'loadSpecificDiscipline']),
    getTippyContent(live: boolean): TippyContent {
      let content = this.disablePlay ? this.$t('trainingHall.notEnoughEnergy') : ''
      if (!content.length && this.isCoolDown && live) {
        content = this.$t('common.cooldown')
      }
      return {
        theme: 'WSM',
        content: content,
        placement: 'top',
        textAlign: 'center',
        appendTo: 'parent',
      }
    },
    attributeAnimation(): void {
      this.change = false
      this.bounce = true
    },
    disableLimitConfirm(): void {
      this.isConfirmAllowed = !this.isConfirmAllowed
      this.setProfileAttributes({
        name: 'training_hall_attribute_limit_warning',
        value: this.isConfirmAllowed,
      })
    },
    onButtonClick(isLive: boolean, forceLimitConfirm: boolean = false) {
      if (this.showReachedLimitPopup && forceLimitConfirm) {
        this.buildingLimitReached = true
        return
      }
      this.buildingLimitReached = false
      if (this.disablePlay) {
        this.setRefreshingBundle({
          type: ENERGY,
          value: this.requiredConsumableValue,
        })
        this.isPopupOpen = true
        return
      }

      if (this.isButtonDisabled !== PlayButtonLock.Unset || this.isSimulation || this.isMinigame)
        return

      this.isButtonDisabled = isLive ? PlayButtonLock.Live : PlayButtonLock.Simulation

      ApiService.$clear()
      this.$debounce((): void => {
        this.playTraining(isLive, forceLimitConfirm)
      })
    },
    async playTraining(isLive: boolean, forceLimitConfirm: boolean = false): Promise<void> {
      this.isDisciplineChanged = false
      if (this.showReachedLimitPopup && forceLimitConfirm) {
        this.buildingLimitReached = true
        return
      }
      this.buildingLimitReached = false
      if (this.disablePlay) {
        this.setRefreshingBundle({
          type: ENERGY,
          value: this.requiredConsumableValue,
        })
        this.isPopupOpen = true
        return
      }

      playSound('click-button-duel-play')

      if (isLive) {
        if (this.discipline.isTutorialPassed || this.actualStage) {
          openMiniGame(
            minigameUrl,
            {
              params: {
                mode: this.miniGameMode,
                discipline_id: this.discipline.id,
                tutorial_id: this.actualStage?.name ?? '',
                multiplier: this.activeMultiplicator(Multiplier.Training).name,
                ...(this.isEventView ? { redirect: this.$route.name } : null),
              },
            },
            this.$router,
          )
        } else {
          this.showSkipTutorialPopup = true
        }
        return
      }
      this.setSimulation(true)
      try {
        const response = await this.$axios.post<{}, TrainingApiResponse>(trainingEndpoint, {
          discipline_id: this.discipline.id,
          type: this.miniGameMode,
          simulation: true,
          game_token: gameToken,
          multiplier: this.activeMultiplicator(Multiplier.Training).name,
        })
        // TODO: temp solution
        if (response?.EXPERIENCE) this.setUserExperience(response.EXPERIENCE)
        if (response?.TARGET_EXPERIENCE)
          this.setUserDataTargetExperience(response.TARGET_EXPERIENCE)
        if (response?.STARTING_EXPERIENCE)
          this.setUserDataStartingExperience(this.levelUpData.STARTING_EXPERIENCE)
        if (response?.LEVEL) this.setUserDataLevel(response.LEVEL)
      } catch (error: unknown) {
        console.error(error)
      }
      if (this.isTrainer) {
        this.$router.push({ name: this.$getWebView('TrainingTrainer') })
        return
      }
      this.setSimulation(false)

      this.loadSpecificDiscipline({ disciplineId: this.discipline.id })
      this.loadDisciplinesForMenu('training_hall')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/training/componentDiscipline.scss';
@import '@/assets/styles/components/icons/discipline-icons.scss';

.attributeIcon {
  width: 3rem;
  height: 3rem;
}

.attributeText {
  margin-right: 0.75rem;
}

.strike {
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    border-bottom: 2px solid red;
    width: 65%;
    position: absolute;
    right: -0.5rem;
    top: 45%;
    -webkit-transform: skewY(-27deg);
    transform: skewY(-27deg);
  }
}

.component-discipline {
  &.is-event-view {
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(to top, #5ba3dc, #bfe3ff, #5ba3dc);
    border-image-slice: 1;
    background-color: #152b45;
  }
}
</style>
