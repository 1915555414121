<template>
  <div
    class="icon-router cursor-pointer flexing"
    :class="{
      'is-active': isActive,
      'event-pass-bg': routeData.routeName === 'EventsPasses',
    }"
    @click="$router.push({ name: $getWebView(routeData.routeName) })"
  >
    <div
      class="revert-skew"
      :class="`icon-events-${routeData.routeName}-${isActive ? 'dark' : 'light'}`"
    />
    <p
      v-if="isActive"
      class="text-34 icon-router-text text-texts-standard-dark font-bold revert-skew"
    >
      {{ $te(tabNames[indice]) }}
    </p>
    <app-notification
      v-if="tabNotificationCount"
      :count="tabNotificationCount"
      class="revert-skew"
    />
  </div>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import type { EventRoute } from '@/interfaces/events/EventViews'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { EventType } from '@/interfaces/events/EventInfo'

import type { PropType } from 'vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'

interface ComponentData {
  EventType: typeof EventType
}

export default defineComponent({
  name: 'RoutingComponent',
  props: {
    routeData: {
      type: Object as PropType<EventRoute>,
      required: true,
    },
    indice: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      EventType,
    }
  },
  computed: {
    ...mapState(useMilestonesStore, {
      claimableMilestonesCount: 'getMilestonesClaimableCount',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    ...mapState(useEventPlayoffStore, {
      playoffState: 'getPlayoffState',
    }),
    isActive(): boolean {
      return this.$route.name.toString().includes(this.routeData.routeName)
    },
    tabNames(): string[] {
      return [
        this.namespace === EventType.DisciplineEvent && 'tabOverview',
        this.namespace === EventType.DisciplineEvent && 'tabArena',
        this.namespace === EventType.DisciplineEvent && 'tabTraining',
        this.namespace === EventType.AutumnFair && 'noticeBoard',
        (this.namespace === EventType.FrozenChampionship ||
          this.namespace === EventType.ChallengeCup) &&
          'tabTaskchain',
        this.namespace === EventType.FrozenChampionship && 'frozenLab',
        (this.namespace === EventType.FrozenChampionship ||
          this.namespace === EventType.ChallengeCup ||
          this.namespace === EventType.AutumnFair) &&
          'tabMilestones',
        this.namespace === EventType.FrozenChampionship && 'tabPlayoff',
        (this.namespace === EventType.ChallengeCup || this.namespace === EventType.AutumnFair) &&
          (this.playoffState?.[0]?.name ?? 'tabPlayoff'),
        this.namespace === EventType.ChallengeCup && 'tabLuckyWheel',
        this.namespace === EventType.AutumnFair && 'carnival',
        (this.namespace === EventType.FrozenChampionship ||
          this.namespace === EventType.ChallengeCup ||
          this.namespace === EventType.AutumnFair) &&
          'tabPass',
        (this.namespace === EventType.FrozenChampionship ||
          this.namespace === EventType.ChallengeCup ||
          this.namespace === EventType.AutumnFair) &&
          'tabOffers',
        this.namespace === EventType.DisciplineEvent && 'tabMasterPass',
        this.namespace === EventType.DisciplineEvent && 'tabRankings',
        this.namespace === EventType.DisciplineEvent && 'tabMilestones',
      ].filter(Boolean)
    },
    tabNotificationCount(): number {
      if (this.tabNames[this.indice] === 'noticeBoard')
        return this.notifications?.events?.[this.namespace]?.unclaimed_competition_milestones ?? 0
      if (this.tabNames[this.indice] === 'tabMilestones') return this.claimableMilestonesCount
      return 0
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/events-icons.scss';

.icon-router {
  display: flex;
  width: 8.5rem;
  height: 5.4375rem;
  transform: $skew-value;
  line-height: 1;
  margin: 0 0.075rem;
  @if $isWsm {
    background: linear-gradient(184deg, #1b394f, #4787b4);
    border: 0.125rem solid #528ebf;
  }
  @if $isSsm {
    background: #354d68;
    border: 0.125rem solid #415e7e;
  }

  &:active {
    transform: $skew-value scale(0.95);
  }

  &:hover {
    background: -webkit-linear-gradient(
      90deg,
      rgb(170, 212, 237) 0%,
      rgb(107, 145, 170) 33%,
      rgb(43, 78, 103) 100%
    );
    border-bottom: 0.25rem solid #dff2ff;
  }

  &-text {
    margin: 0 1rem 0 1rem;
    width: min-content;
  }
}

.is-active {
  border-width: 0.125rem;
  border-style: solid;
  width: 18.75rem;
  border-bottom: 0.25rem solid transparent;
  @if $isWsm {
    border-color: #fff;
    background: linear-gradient(210deg, #f6c717, #ffef84) !important;
  }
  @if $isSsm {
    border-color: #ffdc96;
    background: linear-gradient(210deg, #ffaa3c, #ffe719) !important;
  }
}

.event-pass-bg {
  @if $isWsm {
    background: linear-gradient(
      80deg,
      rgb(199, 111, 224) 0%,
      rgb(159, 59, 210) 100%,
      rgb(159, 72, 172) 100%
    );
  }
  @if $isSsm {
    background: linear-gradient(to right, #861cd4, #cc5df9);
  }

  &:hover {
    @if $isWsm {
      background: linear-gradient(
        0deg,
        rgb(170, 212, 237) 0%,
        rgb(171, 147, 230) 33%,
        rgb(171, 81, 223) 100%
      );
    }
    @if $isSsm {
      background: linear-gradient(to right, #861cd4, #cc5df9);
    }
  }
}
</style>
