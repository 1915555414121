<template>
  <section class="login" :class="{ 'login--portrait': isPortrait }">
    <video-background v-if="!isPortrait" :video-id="YOUTUBE_ID" />
    <main class="w-full h-full homepage relative">
      <info-popup
        v-if="$route.query.rules"
        :popup-title="$t('info.gameRules')"
        :class-list="['homepage-info']"
        @close="closeRulesPopup"
      >
        <rules-content :homepage="true" />
      </info-popup>
      <full-page-scroll id="fullpage-scroll">
        <section class="fullpage-scroll">
          <header class="w-full flex items-center justify-between">
            <app-select
              v-if="formatedLanguages.length > 0"
              :options="formatedLanguages"
              :default="getActualLanguage"
              @select="selectLanguage"
            />
            <div class="social flex items-center text-32">
              <p class="uppercase text-texts-standard-default">
                {{ $t('homepage.visitUs') }}
              </p>
              <a
                v-for="link in socialUrls"
                :key="link"
                class="social-img clickable-element"
                :href="link"
                target="_blank"
              />
            </div>
          </header>
          <div v-if="$isWsm" class="ibu-info text-24 text-texts-standard-default flex items-center">
            <span class="ibu-logo" />
            <span class="ibu-text">Official Partner of the Biathlon Discipline</span>
          </div>

          <div class="homepage-welcome items-center justify-center">
            <transition name="welcome" appear>
              <div class="homepage-welcome-logo">
                <p class="text-texts-standard-additional welcome-text uppercase">Welcome to</p>
                <div class="homepage-welcome-img" />
                <app-store-buttons v-if="!isPortrait" />
              </div>
            </transition>

            <transition name="login" appear>
              <div class="homepage-welcome-login-fixed">
                <login-form-with-flow :context="LoginFormContext.LOGIN" />
              </div>
            </transition>
            <app-store-buttons v-if="isPortrait" is-mobile />
          </div>
        </section>

        <section class="fullpage-scroll">
          <div class="homepage-welcome flex-col justify-center items-start spaced">
            <h1
              class="homepage-welcome-title text-texts-standard-important homepage-welcome-title-bg"
              :class="isPortrait ? 'text-40' : 'text-72'"
            >
              {{ $t('homepage.beChampionTodayTitle') }}
            </h1>
            <p v-if="!isPortrait" class="homepage-welcome-body text-26">
              {{ $t('homepage.beChampionTodayBody') }}
            </p>
            <scroll-text
              v-if="isPortrait"
              class="mt-6 text-26"
              :text="$t('homepage.beChampionTodayBody')"
            />
            <div class="homepage-welcome-images flex items-center">
              <img :src="pathImages + 'homepage/home-img-1.avif'" />
              <img :src="pathImages + 'homepage/home-img-2.avif'" />
            </div>

            <h3
              class="homepage-welcome-title text-texts-standard-important"
              :class="isPortrait ? 'text-26' : 'text-36'"
            >
              {{ $t('homepage.beChampionTodaySubtitle') }}
            </h3>

            <ul class="homepage-welcome-list text-26">
              <li
                v-for="(discipline, key) in disciplines"
                :key="key"
                :class="isPortrait ? 'text-22' : 'text-26'"
              >
                {{ $t('homepage.' + discipline) }}
              </li>
            </ul>
          </div>
        </section>

        <section class="fullpage-scroll">
          <div class="homepage-welcome flex-col justify-center items-start spaced">
            <h1
              class="homepage-welcome-title text-texts-standard-important homepage-welcome-title-bg"
              :class="isPortrait ? 'text-40' : 'text-72'"
            >
              {{ $t('homepage.readyStartTitle') }}
            </h1>
            <p v-if="!isPortrait" class="homepage-welcome-body text-26">
              {{ $t('homepage.readyStartTitleBody') }}
            </p>
            <scroll-text
              v-if="isPortrait"
              class="mt-6 text-26"
              :text="$t('homepage.readyStartTitleBody')"
            />
            <div class="homepage-welcome-images flex items-center">
              <img :src="pathImages + 'homepage/home-img-3.avif'" />
              <img :src="pathImages + 'homepage/home-img-4.avif'" />
            </div>

            <h3
              class="homepage-welcome-title text-texts-standard-important"
              :class="isPortrait ? 'text-26' : 'text-36'"
            >
              {{ $t('homepage.readyStartSubtitle') }}
            </h3>

            <ul class="homepage-welcome-list text-26">
              <li
                v-for="(mechanic, key) in mechanics"
                :key="key"
                :class="isPortrait ? 'text-22' : 'text-26'"
              >
                {{ $t('homepage.' + mechanic) }}
              </li>
            </ul>
          </div>
        </section>

        <footer
          class="homepage-footer items-center flex justify-between text-texts-standard-default absolute w-full"
          :class="isPortrait ? 'text-22' : 'text-26'"
        >
          <div class="left-text flex items-center justify-between">
            <a
              class="clickable-element"
              :href="'https://www.powerplay.studio/' + languageCode + '/license/'"
              target="_blank"
            >
              {{ $t('homepage.license') }}
            </a>
            /
            <a
              class="clickable-element"
              :href="'https://www.powerplay.studio/' + languageCode + '/privacy-policy/'"
              target="_blank"
            >
              {{ $t('homepage.privacyPolicy') }}
            </a>
            /
            <a href="#" class="clickable-element" @click="showCookieSettings">{{
              $t('settings.cookieSettings')
            }}</a>
          </div>
          <p class="copyright">
            Copyright © 2011-{{ new Date().getFullYear() }} - POWERPLAY MANAGER, s.r.o. -
            {{ $t('homepage.allRightsReserved') }}
          </p>
        </footer>
      </full-page-scroll>
    </main>
  </section>
</template>

<script lang="ts">
import FullPageScroll from '@/components/FullPageScroll.vue'
import RulesContent from '@/components/Info/RulesContent.vue'
import LoginFormWithFlow from '@/components/Login/LoginFormWithFlow.vue'
import AppStoreButtons from '@/components/Login/AppStoreButtons.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import VideoBackground from '@/components/VideoBackground.vue'
import ScrollText from '@/components/ScrollText.vue'
import { YOUTUBE_ID, pathImages, wsmFbUrl, wsmIgUrl, ppsIgUrl } from '@/globalVariables'
import { detectLanguage, getCookieConsent, saveTokenToLocalStorage, sendToFlutter } from '@/helpers'
import { loadTextsFromSessionStorage } from '@/plugins/getTexts'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useAccountStore } from '@/store/pinia/accountStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { LoginFormContext } from '@/enums/LoginForm'
import { appleStoreUrl, googleStoreUrl } from '@/globalVariables'

interface ComponentData {
  YOUTUBE_ID: typeof YOUTUBE_ID
  disciplines: string[]
  mechanics: string[]
  googleStoreUrl: typeof googleStoreUrl
  appleStoreUrl: typeof appleStoreUrl
  pathImages: typeof pathImages
  setLanguage: string
  logoutFlutterString: string
  socialUrls: string[]
  LoginFormContext: typeof LoginFormContext
}

export default defineComponent({
  name: 'LoginView',
  components: {
    AppStoreButtons,
    LoginFormWithFlow,
    FullPageScroll,
    VideoBackground,
    InfoPopup,
    RulesContent,
    ScrollText,
  },
  data(): ComponentData {
    return {
      YOUTUBE_ID,
      appleStoreUrl,
      googleStoreUrl,
      disciplines: this.$isWsm
        ? [
            'discipline1',
            'discipline2',
            'discipline3',
            'discipline4',
            'discipline5',
            'discipline6',
            'discipline7',
            'discipline8',
            'discipline9',
            'discipline10',
          ]
        : [
            'discipline1',
            'discipline2',
            'discipline3',
            'discipline4',
            'discipline5',
            'discipline6',
          ],
      mechanics: [
        'readyStartText1',
        'readyStartText2',
        'readyStartText3',
        'readyStartText4',
        'readyStartText5',
        'readyStartText6',
      ],
      pathImages,
      // eslint-disable-next-line no-useless-escape
      setLanguage: '{\r\n  "event": "setLanguage",\r\n "language": "en"\r\n}',
      // eslint-disable-next-line no-useless-escape
      logoutFlutterString: '{"event":"logout"}',
      socialUrls: this.$isWsm ? [wsmFbUrl, wsmIgUrl] : [wsmFbUrl, ppsIgUrl],
      LoginFormContext,
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      formatedLanguages: 'getFormattedLanguages',
    }),
    languageCode(): string {
      return localStorage.getItem('language') === 'sk' || localStorage.getItem('language') === 'cs'
        ? 'sk'
        : 'en'
    },
    getActualLanguage(): string {
      return detectLanguage()
    },
    isPortrait(): boolean {
      return this.$screenOrientation() === 'portrait'
    },
  },
  async created(): Promise<void> {
    this.clearConnectedAccounts()

    await this.loadLanguages(false)
    sendToFlutter(this.logoutFlutterString)

    const accessToken = this.$route?.query?.access_token
    if (accessToken) {
      // dispatch Google btn StorageEvent after successful Google sign in
      saveTokenToLocalStorage(accessToken.toString())
    }
  },
  methods: {
    ...mapActions(useCountriesStore, ['loadLanguages']),
    ...mapActions(useAccountStore, ['clearConnectedAccounts']),
    closeRulesPopup(): void {
      this.$router.replace('/')
    },
    async selectLanguage(languageId: string): Promise<void> {
      localStorage.setItem('language', languageId)
      sessionStorage.removeItem('texts')
      await loadTextsFromSessionStorage()
      this.$t('map.attributes', true)
      window.location.reload()
    },
    showCookieSettings(): void {
      getCookieConsent()?.showSettings(0)
    },
  },
})
</script>

<style lang="scss" scoped>
.homepage {
  .spaced {
    margin-left: 16rem;
  }

  header {
    height: 8.9375rem;
    padding-left: 4.8125rem;

    .social {
      margin-right: 4.875rem;

      &-img {
        height: 4.375rem;
        width: 4.375rem;
        background: url($path-icons + 'ICO_SPRITE_SOCIAL_70px.avif') center no-repeat;
        background-position: 0rem;
        background-size: cover;
        margin: 0 0.5rem;

        &:nth-child(3) {
          background-position: -4.4rem;
        }

        &:nth-child(2) {
          margin-left: 1.125rem;
        }
      }
    }
  }

  .welcome-enter-to {
    left: 10%;
  }

  .welcome-enter-from {
    left: -10rem;
    opacity: 0;
  }

  .welcome-enter-active {
    transition: all 0.5s ease-in;
  }

  .login-enter-to {
    right: 10%;
  }

  .login-enter-from {
    right: -10rem;
    opacity: 0;
  }

  .login-enter-active {
    transition: all 0.5s ease-in;
  }

  &-welcome {
    min-height: -webkit-fill-available;
    display: flex;
    width: 50%;
    padding-top: 1rem;
    height: 100%;

    &-logo {
      animation: slide 1s;
      margin-left: 24rem;
      margin-bottom: 20vh;

      @keyframes slide {
        0% {
          transform: translateX(-200px);
        }

        100% {
          transform: translateX(0px);
        }
      }
    }

    &-login-fixed {
      position: fixed;
      top: 50%;
      right: 16rem;
      z-index: 10;
      transform: translateY(-50%);
    }

    &-title {
      text-shadow: 0.6px 0.8px 3.7px rgba(0, 0, 0, 0.92);
      font-weight: bold;
      font-stretch: condensed;
      line-height: 0.88;
      letter-spacing: normal;
      text-align: left;
      margin-top: 3rem;
      margin-bottom: 3rem;
      text-transform: uppercase;
      margin: 0;
      @if $isWsm {
        font-style: italic;
      }
    }

    &-title-bg {
      background: url($path-homepage + 'bg-title.avif') center no-repeat;
      background-size: 120% 70%;
      padding-left: 5rem;
      padding-right: 5rem;
      margin-left: -5rem;
    }

    &-body {
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-top: 2.5rem;
      @if $isWsm {
        font-style: italic;
      }
    }

    &-images {
      width: 100%;
      margin-bottom: 3rem;
      // margin-bottom: 5.5rem;

      img {
        margin-top: 2.875rem;
        margin-right: 4.375rem;
        width: 31.25rem;
        height: 18.625rem;
      }
    }

    &-list {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      text-align: left;
      padding-left: 2rem;
      margin-top: 1.75rem;
      width: 100%;

      li {
        list-style-type: none;
        color: white;
        text-transform: uppercase;
        line-height: 1.5;
        font-stretch: condensed;
        padding-right: 1.5rem;
        @if $isWsm {
          font-style: italic;
        }

        &::before {
          content: '';
          width: 0.4em;
          height: 0.4em;
          border-radius: 50%;
          float: left;
          line-height: 1em;
          margin: 0.5em 0.3em 0;
          margin-left: -1em;

          @if $isWsm {
            background-color: #fadd1e;
          }

          @if $isSsm {
            background-color: theme('colors.texts.standard.important');
          }
        }
      }
    }

    &-img {
      @include background(null);
      width: 49.75rem;
      height: 40.3125rem;
      margin-top: -6rem;

      @if $isWsm {
        background-image: url('#{$path-homepage}/LOGO_WSM_LOGIN_NEW.avif');
      }

      @if $isSsm {
        background-image: url('#{$path-homepage}/LOGO_SSM_LOGIN_NEW.avif');
      }
    }

    .welcome-text {
      font-size: 4.5rem;
    }
  }

  &-footer {
    position: fixed;
    bottom: 1.375rem;
    padding: 0 4.9375rem;

    .left-text > a:nth-child(3) {
      margin-left: 1rem;
      text-decoration: underline;
    }

    .left-text > a:nth-child(2) {
      margin-right: 1rem;
      margin-left: 1rem;
      text-decoration: underline;
    }

    .left-text > a:nth-child(1) {
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
}

.fullpage-scroll {
  background-color: rgba(4, 11, 21, 0.5);
  height: 100vh;
}

.login {
  &--portrait {
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      background: url($path-homepage + 'BG_FULLSCREEN_MAP_2K.avif') center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100vh;
      z-index: -1;
    }

    & > * {
      z-index: 1;
    }

    :deep() {
      .scroll .navigation-arrows {
        right: calc(50% - 15px);
      }

      .navigation-menu {
        left: 1.875rem;

        .navigation-menu-point {
          width: 1.688rem;
          height: 1.688rem;
        }

        .active {
          width: 1.688rem;
          height: 3.75rem;
        }
      }
    }

    .homepage {
      .fullpage-scroll {
        &:first-of-type {
          padding-top: 2.5rem;

          .homepage-welcome {
            width: 100%;
            margin: 0;
            padding-left: 0;
          }
        }

        .app-select {
          // login-form-with-flow cover select
          z-index: 15;
        }
      }

      header {
        position: relative;
        margin: 0 auto;
        padding: 0;
        height: auto;
        width: calc(100% - 5rem);

        .social {
          position: absolute;
          margin: 0;
          padding: 0;
          flex-direction: column;
          gap: 2.188rem;
          right: 0;
          top: 0;

          .social-img {
            margin: 0;
            padding: 0;
          }

          p {
            display: none;
          }
        }
      }

      &-footer {
        position: absolute;
        flex-direction: column;
      }
    }

    .homepage-welcome {
      flex-direction: column;
      width: 75%;
      margin: 0 auto;

      &-images {
        overflow-x: auto;
      }

      &-logo {
        width: 100%;
        position: relative;
        margin: 0;
        padding: 0;
        animation: none;

        .welcome-text {
          display: none;
        }

        .homepage-welcome-img {
          // with scale 24.219 x 11.938rem
          width: 24.219rem;
          height: 11.938rem;
          margin: 3rem auto;
          background-size: 100% auto;
        }
      }

      &-title-bg {
        background: transparent;
        margin: 0 auto;
        padding: 0;
      }

      &-login-fixed {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        transform: scale(0.75);
        transform-origin: top center;
      }

      &-images {
        width: calc(100% + 4rem);
        padding-bottom: 1.875rem;

        img {
          margin-left: 0;
          margin-right: 1.875rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &-title {
        line-height: 1;
      }
    }
  }
  .ibu-info {
    padding-left: 3.6rem;
    .ibu-logo {
      width: 6.813rem;
      height: 3.938rem;
      background: url($path-icons + 'ibu-logo.avif') center no-repeat;
      background-size: contain;
    }
  }
}
</style>
